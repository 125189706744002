import packageJson from './package.symlink.json';

const transmitAnswerToServer = (questionId, answerKey) => {
    let clock = new Date();

    let payload = {
        epoch: clock.getTime(),
        version: packageJson.version,
        sessionId: sessionStorage.getItem('sessionId'),
        question: questionId,
        answer: answerKey,
        host: window.location.hostname
    };
    
    navigator.sendBeacon('https://www.tastetypes.com/api/', JSON.stringify(payload));
}

export default transmitAnswerToServer;
