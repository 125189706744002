
const MyButton = (props) => {

    const text = props.text;
    const color = props.color;
    const styles = {
        color: 'white',
        backgroundColor: color,
        border: 0,
        fontSize: '125%',
        borderRadius: '0.33em',
        padding: '0.3em',
        margin: '0.5em',
        minWidth: '15em'
    };

    return <button style={styles} onClick={props.clickHandler} type={props.type ? props.type : "button"}>{text}</button>
}
 export default MyButton;