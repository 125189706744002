export default function Instructions() {
    return (
        <main>
            <h1>This is a Taste Survey</h1>
            <p>We have some questions about how you like the <em>taste</em> of various foods.</p>
            <p>
                There are quite a few questions. You can stop any time - the system will record your answers
                up to that point. The data is still valuable to us even if you don't make it to the end.
            </p>
            <h1>Every question is optional</h1>
            <p>
                You can skip any question for any reason or no reason. It is common to have not tired
                particular foods, so don't worry about it.
            </p>

            <h1>Vegan, Kosher, and other dietary restrictions</h1>
            <p>
                This survey is only about <em>taste</em>, so please answer based on whether you
                like or dislike a particular food.
            </p>
            <p>
                For example, our progammer is vegetarian now, but was raised eating meat. They remember
                that they like the taste of hamburger, so they would answer that they "like" beef even
                though they would not eat beef today.
            </p>
            <p>
                In another example, a person who is lactose intolerant might really like ice cream,
                but won't eat ice cream because it would make them sick. They should still mark that
                they like ice cream based on taste.
            </p>
            <hr className="bottomPadding" />
        </main>
    );
}