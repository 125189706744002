import MyButton from "./button";
import questionPool from "./question-pool";
import transmitAnswerToServer from "../../transmitAnswerToServer";

const Question = ( {setQuestionIndex, getQuestionIndex}) => {
    const Data = questionPool[getQuestionIndex];

    const responseButtons = [];

    const clickHandler = (questionId, answerKey) => {
        setQuestionIndex(getQuestionIndex + 1);

        // Store list of answered questions locally

        const current = JSON.parse(
            sessionStorage.getItem('answeredQuestions')
        );
        current.push(questionId);
        sessionStorage.setItem(
            'answeredQuestions', JSON.stringify(current)
            );

        // Send data to server
        transmitAnswerToServer(questionId, answerKey);

        // Scroll user to the top of the page
        window.scrollTo(0, 0);
    }
    Data.options.forEach((input) => {
        responseButtons.push(
            <MyButton
                color={input.color}
                text={input.text}
                id={Data.id}
                key={input.key}
                clickHandler={() => {clickHandler(Data.id, input.key)}}
            />
        );
    });

    const imageSrc = "/images/foods/800width/" + Data.image;
    return (
        <>
            <img src={imageSrc} className="foodImage" alt={Data.name} />
            <h1>{Data.question}</h1>
            <p>{Data.details}</p>
            <p>{responseButtons}</p>
            <p><small>This is question number {getQuestionIndex + 1} of {questionPool.length}</small></p>
            <span className="photoAttribution">
                Photo credit {Data.license} <a href={Data.imageCreditLink} target="_new">{Data.imageCredit}</a>
            </span>

        </>
    );
};

export default Question;