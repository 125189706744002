import { Routes, Route } from "react-router-dom";
import './App.css';
import About from "./components/about";
import Home from "./components/home";
import Navigation from "./components/navigation";
import Survey from "./components/survey/survey";
import Logo from "./components/logo";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
            <Route path="/" element={<Logo />} />
            <Route path="about" element={<Logo />} />
        </Routes>
          <Navigation />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="about" element={<About />} />
        </Routes>
       <br /><br />
      </header>
    </div>
  );
}

export default App;
