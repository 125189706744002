export default function About() {
    return (
        <>
            <main>
                <h1>About Us</h1>
                
                <p>Remember learning about the "taste map" on your tongue? It turns out that it's wrong - very much disproven.[<a href="#ref1">1</a>]</p>

                <p>We're amateur food scientists and are fascinated by how foods taste differently for different people. We have music we like and music we don't care for, and we often have a favorite color. We don't have nearly the disgust for color or music we don't like as we do for the food we don't like.</p>

                <p>We like experimenting with foods, trying new cooking techniques, and testing new combinations. We like making weird marshmallows, blow-torching home-grown mushrooms, and freezing our lab-grown foods in liquid nitrogen.</p>

                <p>We've noticed there are potentially genetic components to the foods we like or foods we hate. You might know someone who can't stand cilantro (for some, it tastes like soap.) You might have done the "super-taster" experiment in school where a piece of paper tastes bitter to some and like normal paper to others.[<a href="#ref2">2</a>]</p>

                <p>What correlations might exist between extreme food preferences? Are there "taste groups" or "taste types" like blood types? Or at least really common bubbles on the Venn diagram of taste preferences? We wonder, and haven't found any good research. Surely "big food" has figured this out, but isn't sharing their secrets. Can we make better menus for restaurants where everyone is sure to like something?</p>

                <p>We're trying to find out.</p>

                <h1>Have questions?</h1>
                <p>
                    We would love to hear from you. Email us at &nbsp; 
                    <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#116;&#097;&#115;&#116;&#101;&#116;&#121;&#112;&#101;&#115;&#046;&#099;&#111;&#109;">
                        &#104;&#101;&#108;&#108;&#111;&#064;&#116;&#097;&#115;&#116;&#101;&#116;&#121;&#112;&#101;&#115;&#046;&#099;&#111;&#109;
                    </a>.
                </p>

                <h1>More Information</h1>
                <p><span id="ref1">[1]</span> Take a look at <a href="https://en.wikipedia.org/wiki/Tongue_map" target="_blank" rel="noreferrer">https://en.wikipedia.org/wiki/Tongue_map</a> if you are interested in the history of this. It's also worth noting that the <a href="https://en.wikipedia.org/wiki/Color_wheel" target="_blank" rel="noreferrer">color wheel</a> you learned with red, blue, and yellow primaries is wrong - that's why you always got mud when you mixed them.</p>
                <p><span id="ref2">[2]</span> People often remember the "super-taster" part of this experiment, but it's actually just showing genetic markers. The paper is doped with PTC (phenylthiocarbamide), which doesn't occur in any food. Read more at <a href="https://en.wikipedia.org/wiki/PTC_tasting" target="_blank" rel="noreferrer">https://en.wikipedia.org/wiki/PTC_tasting</a></p>

            </main>
        </>
    );
}
