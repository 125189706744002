import MyButton from "./button";

const ResetButton = () => {

    const resetSession = () => {
        console.log('Session Reset Requested');
        sessionStorage.removeItem('sessionId');
        sessionStorage.removeItem('answeredQuestions');
        window.scrollTo(0, 0);
        window.location.reload();
    }

    return (
        <>
            <MyButton
                color="darkred"
                text="Reset"
                key=""
                clickHandler={resetSession}
            />
        </>
    )
}

export default ResetButton;

