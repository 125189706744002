import {Link} from "react-router-dom";

export default function Navigation() {
    return (
        <nav>
            <Link to="/">Home</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to="/survey">Survey</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to="/about">About</Link>
            <hr className="bottomPadding" />
        </nav>
    );
}
