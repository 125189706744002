import ResetButton from "./reset"

const Cake = () => {
    return (
        <>
            <p>We've run out of questions to ask you. Thank&nbsp;you so&nbsp;much! Have&nbsp;some&nbsp;cake!</p>
            <img
                src="/images/foods/cake.jpg"
                alt="German chocolate cake."
                />
            <span className="photoAttribution">By Beria. https://en.wikipedia.org/wiki/File:GermanChocolateCake.jpg</span>
            <ResetButton />
        </>
    )
}

export default Cake;
