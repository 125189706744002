const Logo = () => {
    const style = {
        "fontWeight": 200,
        "fontSize": "370%",
        "color": "#00bbbb"
    }
    return (
        <div>
            <h1 style={style}>Taste Types Survey</h1>
        </div>
    );
}

export default Logo;