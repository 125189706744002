import {Text} from "@chakra-ui/react";
import MyButton from "./survey/button";
import {Link} from "react-router-dom";

export default function Home() {
    return (
        <>
            <main>
                <Text>
                    We are studying how people's taste preferences intersect. You can help us invent better foods
                    by helping us gather data through our survey.
                </Text>

                <Text>
                    Are there are common, undiscovered relationships between food preferences?
                    If we compare lots of common foods against each other, will bubbles appear
                    in the Venn diagram? Do people who put ketchup on french fries usually
                    like peanuts? Are there "taste&nbsp;types"?
                </Text>

                <Text>
                    "Big food" certainly has researched this, but isn't giving up their secrets,
                    so we decided to do our own survey. We're inspried by&nbsp;
                    <a
                        style={{"color": "#00bbbb"}}
                        href="https://blog.xkcd.com/2010/05/03/color-survey-results/">Randall&nbsp;Munroe's
                    color survey</a>.
                </Text>
                <Link to="/survey">
                    <MyButton text="Start the survey..." color="teal" />
                </Link>
            </main>
        </>
    );
}
