import Question from "./question.js";
import Cake from "./cake"
import questionPool from "./question-pool";
import {useEffect, useState} from "react";
import Instructions from "./instructions";
import FreeformQuestions from "./freeform";

export default function Survey() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    // Initialize storage and communication
    if (sessionStorage.getItem('answeredQuestions') === null) {
        sessionStorage.setItem('answeredQuestions', JSON.stringify([]));
    }

    // Make session identifier if we don't have one already
    if (sessionStorage.getItem('sessionId') === null) {
        sessionStorage.setItem('sessionId', crypto.randomUUID());
    }

    const [getQuestionIndex, setQuestionIndex] = useState(0);
    const [getFinalState, setFinalState] = useState(false);

    const handleAnswerClick = (input) => {
        setQuestionIndex(getQuestionIndex() + 1);
    }

    // If we're out of questions...
    if ((getQuestionIndex) >= questionPool.length) {
        // If all done, give the user cake
        if (getFinalState === false) {
            return <FreeformQuestions setFinalState={setFinalState}  />
        }

        // Otherwise, give them one last page of freeform questions
        return <Cake />
    }

    // While the session array of answered questions includes the current questionId,
    // advance to the next question index
    let currentQuestionIndex = getQuestionIndex;
    while (sessionStorage.getItem('answeredQuestions').includes(questionPool[currentQuestionIndex].id)) {
        currentQuestionIndex++;
        setQuestionIndex(currentQuestionIndex);
    }

    // If we're still here, give the user a question.
    return (
        <>
            {getQuestionIndex === 0 ? <Instructions /> : ''}
            <Question
                getQuestionIndex={getQuestionIndex}
                setQuestionIndex={setQuestionIndex}
                clickHandler={handleAnswerClick}
            />
        </>
    );
};
