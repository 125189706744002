import React from 'react';
import MyButton from "./button";
import transmitAnswerToServer from "../../transmitAnswerToServer";
import { Formik, Form, Field } from 'formik';
/*
const frameStyle = {
    "margin": "1em",
    "backgroundColor": "pink"
}
*/
const style = {
    "width": "90%",
    "backgroundColor": "#333333",

}

const Cast = (data) => {
    transmitAnswerToServer('favorites', data.favorites);
    transmitAnswerToServer('nemesis', data.nemesis);
    transmitAnswerToServer('comments', data.comments);
}


const FreeformQuestions = ({setFinalState}) => (
    <>
        <Formik
            initialValues={{
                favorites: '',
                nemesis: '',
                comments: ''
            }}
            onSubmit={(values) => {
                Cast(values);
                setFinalState(true);
            }}

        >
            <Form       className="elbowRoom"    >
                <p className={"bottomPadding"}>
                    We're almost finished. These questions are all <em>optional</em>, but we saved you some space to
                    tell us about your most significant tasting foods.
                </p>

                <h1 className={"bottomPadding"}>What are some of the most <em>amazing</em> foods to you?</h1>
                <Field
                    type="textarea"
                    name="favorites"
                    autoFocus
                    style={style}
                />

                <h1 className={"bottomPadding"}>What are some of the <em>worst</em> foods to you?</h1>
                <Field
                    type="textarea"
                    name="nemesis"
                    style={style}
                />

                <h1 className={"bottomPadding"}>Anything else you want us to know?</h1>
                <p className={"bottomPadding"}>
                    It was hard to narrow down this survey to just a few questions - we could have asked over 1000.
                    We're sorry if we left out your favorite foods or that the survey is very American-centric. We had to
                    draw the line somewhere.
                </p>
                <p>
                    If you would like to talk to us about this survey, taste correlation, or modernist cuisine in
                    general, let us know. <em>Don't forget to include your contact information if you want us to
                    reach out to you.</em>
                </p>
                <Field
                    type="textarea"
                    name="comments"
                    style={style}
                />

                <br/>
                <MyButton
                    text="Continue..."
                    color="teal"
                    type="submit"
                />
            </Form>
        </Formik>
    </>
);

export default FreeformQuestions;